import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
    {
        path: '/page',
        title: 'Page',
        icon: 'ft-home',
        class: 'dropdown nav-item',
        isExternalLink: false,
        submenu: [],
    },
    {
        path: '',
        title: 'scenes.menu.title',
        icon: 'ft-align-left',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/scenes/shows',
                title: 'scenes.modules.shows.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
            },
            {
                path: '/scenes/seasons',
                title: 'scenes.modules.seasons.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
            },
        ],
    },
];
