import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TokenStorageService } from './token-storage.service';

import { environment } from 'environments/environment';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { MeService } from './me.service';
import { NgxRolesService } from 'ngx-permissions';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    // Загаловки запросов
    private requestHeader: HttpHeaders;

    // Базовый URL api для аккаунтов
    private baseUrl = `${environment.API_BASE_URL}/api/v1/auth`;

    private tokenExNotificationSubscription: Subscription;

    private subject = new BehaviorSubject<boolean>(false);

    constructor(
        private http: HttpClient,
        private tokenService: TokenStorageService,
        private meService: MeService,
    ) {
        this.requestHeader = new HttpHeaders();
        this.requestHeader.append('Content-Type', 'application/json');
    }

    public login(email: string, password: string) {
        return this.http.post(`${this.baseUrl}/login`, { email, password }).pipe(
            mergeMap((res) => {
                this.setSession(res || {});
                return this.meService.getProfile();
            }),
            map(() => this.subject.next(true)),
            shareReplay(),
        );
    }

    public logout() {
        this.meService.signOut();
        this.tokenService.signOut();
        this.subject.next(false);
        if (this.tokenExNotificationSubscription) {
            this.tokenExNotificationSubscription.unsubscribe();
        }
    }

    public loginNotification(): Observable<boolean> {
        return this.subject.asObservable();
    }

    public isLoggedIn() {
        return this.tokenService.getTokenExpDate()?.getTime() > Date.now();
    }

    public isLoggedOut() {
        return !this.isLoggedIn();
    }

    private setSession(authResult) {
        if (this.tokenExNotificationSubscription) {
            this.tokenExNotificationSubscription.unsubscribe();
        }
        this.tokenExNotificationSubscription = this.tokenService
            .tokenExpNotification()
            .subscribe(() => {
                this.http
                    .post(`${this.baseUrl}/refresh`, {}, { headers: this.requestHeader })
                    .subscribe((res) => this.setSession((res && res['data']) || {}));
            });
        this.tokenService.saveToken(authResult.access_token);
    }
}
