import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '/page',
        title: 'dashboard.menu.title',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
    },
    {
        path: '',
        title: 'home.menu.title',
        icon: 'ft-bookmark',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['banner_read'],
        submenu: [
            {
                path: '/pages/home/banners',
                title: 'banners.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['banner_read'],
                submenu: [],
            },
            {
                path: '/pages/home/news',
                title: 'home-news.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['web-product_read'],
                submenu: [],
            },
            {
                path: '/pages/home/workshops',
                title: 'home-workshops.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['web-product_read'],
                submenu: [],
            },
            {
                path: '/pages/home/shows',
                title: 'home-shows.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['web-product_read'],
                submenu: [],
            },
            {
                path: '/pages/home/products',
                title: 'home-products.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['web-product_read'],
                submenu: [],
            },
            {
                path: '/pages/home/projects',
                title: 'home-projects.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['web-product_read'],
                submenu: [],
            },
            {
                path: '/pages/home/sponsors',
                title: 'sponsors.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['sponsor_read'],
                submenu: [],
            },
        ],
    },
    {
        path: '/news',
        title: 'news.menu.title',
        icon: 'ft-rss',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['news_read'],
        submenu: [],
    },
    {
        path: '',
        title: 'scenes.menu.title',
        icon: 'ft-star',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['web-product_read', 'season_read'],
        submenu: [
            {
                path: '/scenes/seasons',
                title: 'seasons.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['season_read'],
            },
            {
                path: '/scenes/billboards',
                title: 'billboards.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['billboard_read'],
            },
            {
                path: '/scenes/shows',
                title: 'shows.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['web-product_read'],
            },
            {
                path: '',
                title: 'itc-home.menu.title',
                icon: '',
                class: 'dropdown nav-item has-sub',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [
                    {
                        path: '/scenes/itc-home-shows',
                        title: 'itc-home-shows.menu.title',
                        icon: 'ft-arrow-right submenu-icon',
                        class: 'dropdown-item',
                        badge: '',
                        badgeClass: '',
                        isExternalLink: false,
                        submenu: [],
                    },
                ],
            },
        ],
    },
    {
        path: '',
        title: 'workshops.menu.title',
        icon: 'ft-star',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['web-product_read', 'workshop-subscription_read'],
        submenu: [
            {
                path: '/workshops',
                title: 'workshops.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['web-product_read'],
            },
            {
                path: '/workshop-subscriptions',
                title: 'workshopSubscriptions.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['workshop-subscription_read'],
            },
        ],
    },
    {
        path: '/productions/productions',
        title: 'productions.menu.title',
        icon: 'ft-award',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['web-product_read'],
        submenu: [],
    },
    {
        path: '/projects',
        title: 'projects.menu.title',
        icon: 'ft-paperclip',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['web-product_read'],
        submenu: [],
    },
    {
        path: '',
        title: 'pages.menu.title',
        icon: 'ft-message-square',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['static-page_read', 'prizes_read'],
        submenu: [
            {
                path: '/pages/static-pages',
                title: 'static-pages.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['static-page_read'],
            },
            {
                path: '/prizes',
                title: 'prizes.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['prizes_read'],
            },
        ],
    },
    {
        path: '',
        title: 'access.menu.title',
        icon: 'ft-box',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['user_create', 'role_create'],
        submenu: [
            {
                path: '/access/users',
                title: 'users.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['user_create'],
            },
            {
                path: '/access/roles',
                title: 'roles.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: [],
                permissions: ['role_create'],
            },
        ],
    },
    // {
    //     path: '',
    //     title: 'reports.menu.title',
    //     icon: 'ft-box',
    //     class: 'dropdown nav-item has-sub',
    //     badge: '',
    //     badgeClass: '',
    //     isExternalLink: false,
    //     permissions: ['user_read', 'role_read'],
    //     submenu: [
    //         {
    //             path: '/reports/subscribes',
    //             title: 'subscribes.menu.title',
    //             icon: 'ft-arrow-right submenu-icon',
    //             class: 'dropdown-item',
    //             badge: '',
    //             badgeClass: '',
    //             isExternalLink: false,
    //             submenu: [],
    //         },
    //         {
    //             path: '/reports/cash-in',
    //             title: 'cash-in.menu.title',
    //             icon: 'ft-arrow-right submenu-icon',
    //             class: 'dropdown-item',
    //             badge: '',
    //             badgeClass: '',
    //             isExternalLink: false,
    //             submenu: [],
    //         },
    //     ],
    // },
    {
        path: '',
        title: 'dictionaries.menu.title',
        icon: 'ft-box',
        class: 'dropdown nav-item has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        permissions: ['product-main-category_create', 'tag-product-detail_create', 'tag-age-range_create', 'place_create', 'person_create'],
        submenu: [
            /*{
				path: '/dictionaries/languages',
				title: 'languages.menu.title',
				icon: 'ft-arrow-right submenu-icon',
				class: 'dropdown-item',
				badge: '',
				badgeClass: '',
				isExternalLink: false,
				submenu: [],
			},*/
            {
                path: '/dictionaries/main-categories',
                title: 'main-categories.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['product-main-category_create'],
                submenu: [],
            },
            {
                path: '/dictionaries/tag-product-details',
                title: 'tag-product-details.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['tag-product-detail_create'],
                submenu: [],
            },
            {
                path: '/dictionaries/tag-age-ranges',
                title: 'tag-age-ranges.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['tag-age-range_create'],
                submenu: [],
            },
            {
                path: '/dictionaries/places',
                title: 'places.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['place_create'],
                submenu: [],
            },
            {
                path: '/dictionaries/persons',
                title: 'persons.menu.title',
                icon: 'ft-arrow-right submenu-icon',
                class: 'dropdown-item',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                permissions: ['person_create'],
                submenu: [],
            },
        ],
    },
];
