<div
        class="img-ul"
        fileDrop
        [accept]="supportedExtensions"
        (fileOver)="onFileOver($event)"
        (fileDrop)="onFileChange($event)"
        [ngClass]="{'img-ul-file-is-over': fileOver}"
        [ngStyle]="style?.layout"
>
    <div class="img-ul-file-upload img-ul-hr-inline-group">
        <label *ngIf="fileCounter != max"
               class="img-ul-upload img-ul-button btn btn-outline-primary"
               [ngStyle]="style?.selectButton"
               [ngClass]="{'img-ul-disabled': disabled}">
            <span [innerText]="buttonCaption"></span>
            <input
                    type="file"
                    [disabled]="disabled"
                    [accept]="supportedExtensions"
                    multiple (change)="onFileChange(input.files)"
                    #input>
        </label>
        <button *ngIf="fileCounter > 1"
                [disabled]="disabled"
                class="img-ul-clear img-ul-button btn btn-outline-warning"
                (click)="onDeleteAll($event)"
                [ngStyle]="style?.clearButton"
                [innerText]="clearButtonCaption">
        </button>
        <div class="img-ul-drag-box-msg" *ngIf="fileCounter != max" [innerText]="dropBoxMessage"></div>
    </div>

    <p class="img-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>

    <div *ngIf="preview" class="img-ul-container img-ul-hr-inline-group" [ngStyle]="style?.previewPanel">
        <div
                class="img-ul-image"
                *ngFor="let file of files">
            <div class="img-ul-image-bg"
                 (click)="previewFileClicked(file, content)"
                 [ngStyle]="{'background-image': 'url('+ file.src +')'}">
                <div *ngIf="file.pending" class="img-ul-loading-overlay">
                    <div class="img-ul-spinning-circle"></div>
                </div>
            </div>
            <div *ngIf="!file.pending"
                 [ngClass]="{'img-ul-disabled': disabled}"
                 class="img-ul-x-mark"
                 (click)="onDeleteFile($event, file)">
                <span class="img-ul-close"></span>
            </div>
            <div class="img-ul-image-title">{{getFileName(file)}}</div>
        </div>
    </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" translate>general.image-preview</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body text-center">
        <img alt="" [src]="previewImageSrc" class="img-fluid"/>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="c('Close click')" translate>Close</button>
    </div>
</ng-template>
