import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';

// COMPONENTS
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './vertical-menu/vertical-menu.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ImageUploadComponent } from './upload/components/image-upload/image-upload.component';
import { Base64ImageComponent } from './upload/components/base64-image/base64-image.component';
import { FileUploadComponent } from './upload/components/file-upload/file-upload.component';

// DIRECTIVES
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';
import { TableSortableHeaderDirective } from './directives/sortable.directive';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FileDropDirective } from './upload/file-drop.directive';

// SERVICES
import { ImageService } from './upload/services/image.service';
import { FileService } from './upload/services/file.service';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { AuthModule } from '../auth/auth.module';
import { DateTimePickerComponent } from './datetimepicker/datetimepicker.component';

@NgModule({
    exports: [
        CommonModule,
        NgbModule,
        TranslateModule,
        NgxSpinnerModule,
        NgxPermissionsModule,
        EditorModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        TableSortableHeaderDirective,
        SpinnerComponent,
        ImageUploadComponent,
        Base64ImageComponent,
        FileUploadComponent,
        DateTimePickerComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        NgxSpinnerModule,
        AuthModule,
        NgxPermissionsModule.forChild(),
        EditorModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        TableSortableHeaderDirective,
        SpinnerComponent,
        ImageUploadComponent,
        Base64ImageComponent,
        FileUploadComponent,
        FileDropDirective,
        DateTimePickerComponent,
    ],
    providers: [
        ImageService,
        FileService,
        CanDeactivateGuard,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
