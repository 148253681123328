import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { TitleService } from './shared/services/title.service';
import { NgxRolesService } from 'ngx-permissions';
import { AuthService } from './auth/services/auth.service';
import { MeService } from './auth/services/me.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    userLoginSub: Subscription;

    constructor(
        private router: Router,
        private titleService: TitleService,
        private rolesService: NgxRolesService,
        private authService: AuthService,
        private meService: MeService,
    ) {}

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => window.scrollTo(0, 0));
        this.titleService.init();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.userLoginSub) {
            this.userLoginSub.unsubscribe();
        }
    }
}
