<nav class='navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}'
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
    <div class='container-fluid navbar-wrapper'>
        <div class='navbar-header d-flex'>
            <div
                    class='navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center'
                    data-toggle='collapse' (click)='toggleSidebar()'>
                <i class='ft-menu font-medium-3'></i>
            </div>
            <ul class='navbar-nav' (clickOutside)='toggleSearchOpenClass(false)'>
                <li class='nav-item mr-2 d-none d-lg-block'>
                    <a class='nav-link apptogglefullscreen' id='navbar-fullscreen' href='javascript:;'
                       appToggleFullscreen
                       (click)='ToggleClass()'><i class=' {{toggleClass}} font-medium-3'></i></a>
                </li>
                <li class='nav-item nav-search'>
                    <a class='nav-link nav-link-search' id='navbar-search' href='javascript:'
                       (click)='toggleSearchOpenClass(true)'><i
                            class='ft-search font-medium-3'></i></a>
                    <div class='search-input {{searchOpenClass}}'>
                        <div class='search-input-icon'>
                            <i class='ft-search font-medium-3'></i>
                        </div>
                        <input [formControl]='control' #search class='input' type='text' placeholder='Explore Apex...'
                               tabindex='0'
                               autofocus data-search='template-search' [appAutocomplete]='autocomplete'
                               (keyup.enter)='onEnter()' (keydown.esc)='onEscEvent()' (keyup)='onSearchKey($event)'/>
                        <div class='search-input-close' (click)='toggleSearchOpenClass(false)'>
                            <i class='ft-x font-medium-3'></i>
                        </div>

                        <app-autocomplete #autocomplete='appAutocomplete'>
                            <ng-template appAutocompleteContent>
                                <ng-container *ngIf='(listItems | filter: control.value) as result'>
                                    <app-option #searchResults *ngFor='let option of result' [url]='option.url'
                                                [value]='option.name' (click)='redirectTo(option.url);'
                                                (mouseenter)='removeActiveClass()'>
                                        <div class='d-flex align-items-center justify-content-between w-100'>
                                            <div class='d-flex align-items-center justify-content-start'>
                                                <i class='{{option.icon}} mr-2'></i>
                                                <span>{{ option.name }}</span>
                                            </div>
                                        </div>
                                    </app-option>
                                    <app-option class='no-result' *ngIf='!result.length'>No results found.</app-option>
                                </ng-container>
                            </ng-template>
                        </app-autocomplete>
                    </div>
                </li>
            </ul>
            <div class='navbar-brand-center'>
                <div class='navbar-header'>
                    <ul class='navbar-nav'>
                        <li class='nav-item'>
                            <div class='logo'><a class='logo-text' [routerLink]="['/']"
                                                 *ngIf="config?.layout.menuPosition === 'Top'">
                                <div class='logo-img'><img class='logo-img' alt='Apex logo' [src]='logoUrl'></div>
                                <span
                                        class='text'>GTA</span>
                            </a></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class='navbar-container'>
            <div class='collapse navbar-collapse d-block' id='navbarSupportedContent'>
                <ul class='navbar-nav'>
                    <li class='i18n-dropdown dropdown nav-item mr-2' display='dynamic' ngbDropdown>
                        <a class='nav-link d-flex align-items-center dropdown-toggle dropdown-language'
                           id='dropdown-flag'
                           href='javascript:;' data-toggle='dropdown' ngbDropdownToggle><img
                                class='langimg selected-flag'
                                [src]='selectedLanguageFlag' alt='flag'/><span
                                class='selected-language d-md-flex d-none'>{{selectedLanguageText | translate}}</span></a>
                        <div ngbDropdownMenu class='dropdown-menu dropdown-menu-right text-left'
                             aria-labelledby='dropdown-flag'>
                            <a class='dropdown-item' href='javascript:;' data-language='en'
                               (click)="ChangeLanguage('en')">
                                <img class='langimg mr-2' src='./assets/img/flags/en.png' alt=''/>
                                <span class='font-small-3' translate>general.languages.en.title</span>
                            </a>
                            <a class='dropdown-item' href='javascript:;' data-language='it'
                               (click)="ChangeLanguage('it')">
                                <img class='langimg mr-2' src='./assets/img/flags/it.png' alt=''/>
                                <span class='font-small-3' translate>general.languages.it.title</span>
                            </a>
                        </div>
                    </li>
                    <li class='dropdown nav-item mr-1' placement='bottom-left' display='static' ngbDropdown>
                        <a class='nav-link dropdown-toggle user-dropdown d-flex align-items-end' id='dropdownBasic2'
                           href='javascript:;' data-toggle='dropdown' ngbDropdownToggle>
                            <div class='user d-md-flex d-none mr-2'>
                                <span class='text-right'>John Doe</span><span
                                    class='text-right text-muted font-small-3'>Available</span>
                            </div>
                            <img class='avatar' src='assets/img/portrait/small/avatar-s-1.png' alt='avatar' height='35'
                                 width='35'/>
                        </a>
                        <div class='dropdown-menu text-left dropdown-menu-right m-0 pb-0'
                             aria-labelledby='dropdownBasic2'
                             ngbDropdownMenu>
                            <a class='dropdown-item' href='javascript:;'>
                                <div class='d-flex align-items-center'>
                                    <i class='ft-message-square mr-2'></i><span>Chat</span>
                                </div>
                            </a>
                            <a class='dropdown-item' href='javascript:;'>
                                <div class='d-flex align-items-center'>
                                    <i class='ft-edit mr-2'></i><span>Edit Profile</span>
                                </div>
                            </a>
                            <div class='dropdown-divider'></div>
                            <a class='dropdown-item' routerLink='/auth/login'>
                                <div class='d-flex align-items-center'>
                                    <i class='ft-power mr-2'></i><span>Logout</span>
                                </div>
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>
