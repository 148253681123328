import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserView } from '../models/user.view';
import { NgxRolesService } from 'ngx-permissions';

const USER_VIEW = 'uv';

@Injectable({
    providedIn: 'root',
})
export class MeService {
    private baseUrl: string;

    constructor(public readonly http: HttpClient, private rolesService: NgxRolesService) {
        this.baseUrl = `${environment.API_BASE_URL}/api/v1/me`;
        this.initPermissions();
    }

    getProfile(): Observable<UserView> {
        const userView = this.getUserView();
        if (!!userView) {
            of(userView);
        }
        const requestQueryBuilder = RequestQueryBuilder.create().setJoin({
            field: 'role.grants',
        });
        return this.http.get<any>(`${this.baseUrl}?${requestQueryBuilder.query(false)}`).pipe(
            map((user) => {
                const view: UserView = {
                    email: user.email,
                    displayName: user.displayName,
                    role: user.role.name,
                    grants: user.role.grants.map((g) => `${g.resource}_${g.action}`),
                };
                this.saveUserView(view);
                this.initPermissions();
                return view;
            }),
        );
    }

    public signOut() {
        sessionStorage.removeItem(USER_VIEW);
        this.rolesService.flushRolesAndPermissions();
    }

    private initPermissions() {
        this.rolesService.flushRolesAndPermissions();
        const userView = this.getUserView();
        if (!!userView) {
            this.rolesService.addRoleWithPermissions(userView.role, userView.grants);
        }
    }

    private saveUserView(userView: UserView) {
        sessionStorage.removeItem(USER_VIEW);
        if (userView) {
            sessionStorage.setItem(USER_VIEW, JSON.stringify(userView));
        }
    }

    private getUserView(): UserView {
        const str = sessionStorage.getItem(USER_VIEW);
        if (!str || str.length === 0) {
            return null;
        }
        return JSON.parse(str);
    }
}
