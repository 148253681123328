<div class="form-group">
    <label class="uploader"
           [style.width]="width+'px'"
           [style.height]="height+'px'">
        <i class="icon icon-upload"></i>
        <img [src]="sanitize(filePreview)" [ngClass]="{'loaded': !!filePreview}"/>
        <!--<label *ngIf="!filePreview"
               class="b64-image-ul-upload b64-image-ul-button">
            <span>Select Image</span>
        </label>-->
    </label>
    <input type="file" name="image" accept="image/*" (change)="onFileChanged($event)"/>
</div>
