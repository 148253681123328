<div
        class="file-ul"
        fileDrop
        [accept]="supportedExtensions"
        (fileOver)="onFileOver($event)"
        (fileDrop)="onFileChange($event)"
        [ngClass]="{'file-ul-file-is-over': fileOver}"
        [ngStyle]="style?.layout">
    <div class="file-ul-file-upload file-ul-hr-inline-group">
        <label *ngIf="fileCounter != max"
               class="file-ul-upload file-ul-button"
               [ngStyle]="style?.selectButton"
               [ngClass]="{'file-ul-disabled': disabled}">
            <span [innerText]="buttonCaption"></span>
            <input
                    type="file"
                    [disabled]="disabled"
                    [accept]="supportedExtensions"
                    multiple (change)="onFileChange(input.files)"
                    #input>
        </label>
        <button *ngIf="fileCounter > 0"
                [disabled]="disabled"
                class="file-ul-clear file-ul-button"
                (click)="onDeleteAll($event)"
                [ngStyle]="style?.clearButton"
                [innerText]="clearButtonCaption">
        </button>
        <div class="file-ul-drag-box-msg" *ngIf="fileCounter != max" [innerText]="dropBoxMessage"></div>
    </div>

    <p class="file-ul-file-too-large" *ngIf="showFileTooLargeMessage" [innerText]="fileTooLargeMessage"></p>

    <div *ngIf="preview" class="file-ul-container file-ul-hr-inline-group" [ngStyle]="style?.previewPanel">
        <div
                class="file-ul-image"
                *ngFor="let file of files">
            <div class="file-ul-image-bg"
                 (click)="previewFileClicked(file)"
                 [ngStyle]="{'background-image': 'url(/assets/img/svg/files/'+ getFileExtensionImage(file) +'.svg)'}">
                <div *ngIf="file.pending" class="file-ul-loading-overlay">
                    <div class="file-ul-spinning-circle"></div>
                </div>
            </div>
            <div *ngIf="!file.pending"
                 [ngClass]="{'file-ul-disabled': disabled}"
                 class="file-ul-x-mark"
                 (click)="onDeleteFile($event, file)">
                <span class="file-ul-close"></span>
            </div>
            <div class="file-ul-image-title">{{getFileName(file)}}</div>
        </div>
    </div>
</div>
