import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnChanges {
    @Input() showSpinner = false;

    constructor(private spinner: NgxSpinnerService) {}

    ngOnChanges(changes: SimpleChanges) {
        const propSpinnerShow = changes['showSpinner'];
        if (!!propSpinnerShow) {
            propSpinnerShow.currentValue === true ? this.spinner.show() : this.spinner.hide();
        }
    }

    public show(): Promise<unknown> {
        return this.spinner.show();
    }

    public hide(): Promise<unknown> {
        return this.spinner.hide();
    }
}
