import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';
import { QuerySortOperator } from '@nestjsx/crud-request/lib/types/request-query.types';

export type SortDirection = QuerySortOperator | '';
const rotate: { [key: string]: SortDirection } = { ASC: 'DESC', DESC: '', '': 'ASC' };

export interface SortEvent {
    column: string;
    direction: SortDirection;
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'th[sortable]',
})
export class TableSortableHeaderDirective {
    @Input() sortable: string;
    @Input() direction: SortDirection = null;
    @Output() sort = new EventEmitter<SortEvent>();

    @HostBinding('class.asc') isClassAsc = false;
    @HostBinding('class.desc') isClassDesc = false;

    @HostListener('click') onClick() {
        this.rotate();
    }

    public resetDirection() {
        this.direction = '';
        this.updateClass();
    }

    private rotate() {
        this.direction = rotate[this.direction || ''];
        this.sort.emit({ column: this.sortable, direction: this.direction });
        this.updateClass();
    }

    public updateClass() {
        this.isClassAsc = this.direction === 'ASC';
        this.isClassDesc = this.direction === 'DESC';
    }
}
