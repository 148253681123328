import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ImageService } from '../../services/image.service';
import { Style } from '../../models/style';
import { UploadMetadata } from '../../models/before-upload.interface';
import { FileHolder } from '../../models/file-holder';

import * as alertFunctions from '../../../services/sweet-alerts';

import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit, OnChanges {
    files: FileHolder[] = [];
    fileCounter = 0;
    fileOver = false;
    showFileTooLargeMessage = false;

    private pendingFilesCounter = 0;
    private uploadedFilesLocalChange = false;

    private previewImageSrc: string;

    @Input() buttonCaption = 'Select Images';
    @Input() disabled = false;
    @Input() clearButtonCaption = 'Clear';
    @Input() dropBoxMessage = 'o Trascina le immagini qui!'; //Drop your images here!
    @Input() fileTooLargeMessage;
    @Input() headers: Headers | { [name: string]: any };
    @Input() max = 100;
    @Input() maxFileSize: number = environment.IMAGE_UPLOAD_MAX_SIZE;
    @Input() preview = true;
    @Input() partName: string;
    @Input() style: Style;
    // tslint:disable-next-line:no-input-rename
    @Input('extensions') supportedExtensions: string[];
    @Input() url: string;
    @Input() urlRemove: string;
    @Input() withCredentials = false;

    @Input() uploadedFiles: string[] = [];

    @Output() uploadedFilesChange = new EventEmitter<string[]>();

    @Output() removed = new EventEmitter<FileHolder>();
    @Output() uploadStateChanged = new EventEmitter<boolean>();
    @Output() uploadFinished = new EventEmitter<FileHolder>();

    @ViewChild('input', { static: true })
    private inputElement: ElementRef;

    @Input() beforeUpload: (param: UploadMetadata) => UploadMetadata | Promise<UploadMetadata> = (
        data,
    ) => data;

    constructor(private imageService: ImageService, private modalService: NgbModal) {}

    ngOnInit() {
        if (!this.fileTooLargeMessage) {
            this.fileTooLargeMessage =
                'An image was too large and was not uploaded.' +
                (this.maxFileSize
                    ? ' The maximum file size is ' + this.maxFileSize / 1024 + 'KiB.'
                    : '');
        }
        this.supportedExtensions = this.supportedExtensions
            ? this.supportedExtensions.map((ext) => 'image/' + ext)
            : ['image/*'];
    }

    ngOnChanges(changes) {
        if (changes.uploadedFiles && changes.uploadedFiles.currentValue.length > 0) {
            if (!this.uploadedFilesLocalChange) {
                this.processUploadedFiles();
            } else {
                this.uploadedFilesLocalChange = false;
            }
        }
    }

    onDeleteAll(e) {
        e.preventDefault();
        alertFunctions.ConfirmCancelButton().then(
            (result) => {
                if (result && result.dismiss) {
                    return;
                }
                this.files.forEach((f) => {
                    this.removed.emit(f);
                });
                this.files = [];
                this.fileCounter = 0;
                if (this.inputElement) {
                    this.inputElement.nativeElement.value = '';
                }
                this.updateUploadedFiles();
            },
            // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
            () => {},
        );
    }

    onDeleteFile(e, file: FileHolder): void {
        e.preventDefault();
        alertFunctions.ConfirmCancelButton().then(
            (result) => {
                if (result && result.dismiss) {
                    return;
                }
                this.deleteFile(file);
            },
            // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
            () => {},
        );
    }

    deleteFile(file: FileHolder, onlyLocal: boolean = false): void {
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
        this.fileCounter--;
        if (this.inputElement) {
            this.inputElement.nativeElement.value = '';
        }
        this.removed.emit(file);
        if (!onlyLocal) {
            this.updateUploadedFiles();
        }
    }

    previewFileClicked(file: FileHolder, content) {
        this.previewImageSrc = file.file.name;
        const modalRef = this.modalService.open(content, { centered: true, size: 'lg' });
        modalRef.result.then(
            () => {
                this.previewImageSrc = null;
            },
            () => {
                this.previewImageSrc = null;
            },
        );
    }

    onFileChange(files: FileList) {
        if (this.disabled) {
            return;
        }

        const remainingSlots = this.countRemainingSlots();
        const filesToUploadNum = files.length > remainingSlots ? remainingSlots : files.length;

        if (this.url && filesToUploadNum !== 0) {
            this.uploadStateChanged.emit(true);
        }

        this.fileCounter += filesToUploadNum;
        this.showFileTooLargeMessage = false;
        this.uploadFiles(files, filesToUploadNum);
    }

    onFileOver = (isOver) => (this.fileOver = isOver);

    private countRemainingSlots = () => this.max - this.fileCounter;

    private onResponse(response: Object, fileHolder: FileHolder) {
        fileHolder.serverResponse = response; // {status: response.status, response};
        fileHolder.pending = false;

        this.uploadFinished.emit(fileHolder);

        this.updateUploadedFiles();

        if (--this.pendingFilesCounter === 0) {
            this.uploadStateChanged.emit(false);
        }
    }

    private processUploadedFiles() {
        console.log('processUploadedFiles');
        this.files = [];
        this.fileCounter = 0;
        if (this.inputElement) {
            this.inputElement.nativeElement.value = '';
        }
        for (let i = 0; i < this.uploadedFiles.length; i++) {
            const data: any = this.uploadedFiles[i];

            let fileBlob: Blob, file: File, fileUrl: string;

            if (data instanceof Object) {
                fileUrl = data.url;
                fileBlob = data.blob ? data.blob : new Blob([data]);
                file = new File([fileBlob], data.fileName);
            } else {
                fileUrl = `${this.url}/${data}`;
                fileBlob = new Blob([fileUrl]);
                file = new File([fileBlob], fileUrl);
            }
            const fHolder = new FileHolder(fileUrl, file);
            fHolder.serverResponse = { data: { file: data } };
            this.files.push(fHolder);
        }
        this.fileCounter = this.files.length;
    }

    updateUploadedFiles() {
        const arr: string[] = [];
        this.files.forEach((file) => {
            if (file.serverResponse && file.serverResponse['data']) {
                arr.push(file.serverResponse['data'].file);
            }
        });
        this.uploadedFilesLocalChange = true;
        this.uploadedFiles = Object.assign([], arr);
        this.uploadedFilesChange.emit(this.uploadedFiles);
    }

    private async uploadFiles(files: FileList, filesToUploadNum: number) {
        for (let i = 0; i < filesToUploadNum; i++) {
            const file = files[i];

            if (this.maxFileSize && file.size > this.maxFileSize) {
                this.fileCounter--;
                if (this.inputElement) {
                    this.inputElement.nativeElement.value = '';
                }
                this.showFileTooLargeMessage = true;
                continue;
            }

            const beforeUploadResult: UploadMetadata = await this.beforeUpload({
                file,
                url: this.url,
                abort: false,
            });

            if (beforeUploadResult.abort) {
                this.fileCounter--;
                if (this.inputElement) {
                    this.inputElement.nativeElement.value = '';
                }
                continue;
            }

            const img = document.createElement('img');
            img.src = window.URL.createObjectURL(beforeUploadResult.file);

            const reader = new FileReader();
            reader.addEventListener(
                'load',
                (event: any) => {
                    const fileHolder: FileHolder = new FileHolder(
                        event.target.result,
                        beforeUploadResult.file,
                    );
                    this.uploadSingleFile(
                        fileHolder,
                        beforeUploadResult.url,
                        beforeUploadResult.formData,
                    );
                    this.files.push(fileHolder);
                },
                false,
            );
            reader.readAsDataURL(beforeUploadResult.file);
        }
    }

    private uploadSingleFile(
        fileHolder: FileHolder,
        url = this.url,
        customForm?: { [name: string]: any },
    ) {
        if (url) {
            this.pendingFilesCounter++;
            fileHolder.pending = true;

            this.imageService.postImage(url, fileHolder.file, this.partName, customForm).subscribe(
                (response) => this.onResponse(response, fileHolder),
                (error) => {
                    this.onResponse(error, fileHolder);
                    this.deleteFile(fileHolder, true);
                },
            );
        } else {
            this.uploadFinished.emit(fileHolder);
        }
    }

    private getFileName(fileHolder: FileHolder): string {
        let name = '';

        if (fileHolder && fileHolder.serverResponse && fileHolder.serverResponse['data']) {
            name = fileHolder.serverResponse['data'].file;
        } else if (fileHolder && fileHolder.file && fileHolder.file.name) {
            name = fileHolder.file.name.slice(
                // tslint:disable-next-line:no-bitwise
                ((fileHolder.file.name.lastIndexOf('/') - 1) >>> 0) + 2,
            );
        }
        if (name && name.length) {
            // tslint:disable-next-line:no-bitwise
            name = name.slice(((name.indexOf('-') - 1) >>> 0) + 2);
        }

        return name || '';
    }
}
