import { Component, forwardRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-base64-image',
    templateUrl: './base64-image.component.html',
    styleUrls: ['./base64-image.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => Base64ImageComponent),
            multi: true,
        },
    ],
})
export class Base64ImageComponent implements ControlValueAccessor {
    fileName: string;

    @Input() width = '300';
    @Input() height = '300';
    @Input() _filePreview = '';

    constructor(private sanitizer: DomSanitizer) {}

    onFileChanged(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.fileName = file.name + ' ' + file.type;
                this.filePreview =
                    'data:image/png' + ';base64,' + (reader.result as string).split(',')[1];
            };
        }
    }

    sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    propagateChange = (_: any) => {};

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched() {}

    writeValue(value: any) {
        if (value !== undefined) {
            this.filePreview = value;
        }
    }

    get filePreview() {
        return this._filePreview;
    }

    set filePreview(val) {
        this._filePreview = val;
        this.propagateChange(this._filePreview);
    }
}
