// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    API_BASE_URL: '',
    FILE_UPLOAD_MAX_SIZE: 20 * 1024 * 1024,
    FILE_UPLOAD_API_PATH: '/api/v1/files',
    IMAGE_UPLOAD_MAX_SIZE: 20 * 1024 * 1024,
    IMAGE_UPLOAD_API_PATH: '/api/v1/images',
    FILE_MANAGER_API_KEY: '9A9FAKQK56qp6dM#dedf?-ij4hDMR2r$A46ppBpH0kv5giC',
    PRODUCT_TYPE_IDS: {
        SHOWS: 1,
        WORKSHOPS: 2,
        PRODUCTS: 3,
        PROJECTS: 4,
    },
};
