import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthInterceptor } from './services/auth.interceptor';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { TokenStorageService } from './services/token-storage.service';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { AuthComponent } from './components/auth.component';
import { MeService } from './services/me.service';

@NgModule({
    declarations: [AuthComponent, LoginPageComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        NgbModule,
        NgxSpinnerModule,
        AuthRoutingModule,
    ],
    providers: [
        AuthService,
        AuthGuard,
        TokenStorageService,
        MeService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    ],
})
export class AuthModule {}
