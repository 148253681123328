import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements AfterViewInit {
    loginFormSubmitted = false;
    isLoginFailed = false;

    loginForm = new FormGroup({
        username: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.min(6)]),
        rememberMe: new FormControl(true),
    });

    constructor(
        private router: Router,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute,
    ) {}

    ngAfterViewInit(): void {
        this.authService.logout();
    }

    get lf() {
        return this.loginForm.controls;
    }

    // On submit button click
    onSubmit() {
        this.loginFormSubmitted = true;
        if (this.loginForm.invalid) {
            return;
        }

        this.spinner.show(undefined, {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true,
        });

        this.authService
            .login(this.loginForm.value.username, this.loginForm.value.password)
            .subscribe(
                (resp) => {
                    this.spinner.hide();
                    this.router.navigate(['/page']);
                },
                (err) => {
                    this.isLoginFailed = true;
                    this.spinner.hide();
                    console.log('error: ' + JSON.stringify(err));
                },
            );
    }
}
