import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../../../auth/services/token-storage.service';

@Injectable()
export class FileService {
    constructor(private http: HttpClient, private tokenService: TokenStorageService) {}

    save(
        url: string,
        file: File,
        partName: string = 'file',
        customFormData?: { [name: string]: any },
    ): Observable<Object> {
        if (!(url && url !== '')) {
            throw new Error('Url is not set! Please set it before doing queries');
        }
        // add custom form data
        const formData = new FormData();
        // tslint:disable-next-line:forin
        for (const key in customFormData || []) {
            formData.append(key, customFormData[key]);
        }
        formData.append(partName, file);
        return this.http.post(url, formData);
    }

    delete(url: string, name: string): Observable<Object> {
        return this.http.delete(`${url}/${name}`);
    }

    /**
     * download file from server
     * @param {string} id - file identifier
     */
    download(url: string, name: string) {
        window.open(`${url}/${name}?auth_token=${this.tokenService.getToken()}`);
    }
}
