import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
    {
        path: 'page',
        loadChildren: () => import('../../page/page.module').then((m) => m.PageModule),
    },
    {
        path: 'news',
        loadChildren: () => import('../../news/news.module').then((m) => m.NewsModule),
    },
    {
        path: 'scenes',
        loadChildren: () => import('../../scenes/scenes.module').then((m) => m.ScenesModule),
    },
    {
        path: 'workshops',
        loadChildren: () =>
            import('../../workshops/workshops.module').then((m) => m.WorkshopsModule),
    },
    {
        path: 'workshop-subscriptions',
        loadChildren: () =>
            import('../../workshop-subscriptions/workshop-subscriptions.module').then(
                (m) => m.WorkshopSubscriptionsModule,
            ),
    },
    {
        path: 'productions/productions',
        loadChildren: () => import('../../products/products.module').then((m) => m.ProductsModule),
    },
    {
        path: 'projects',
        loadChildren: () => import('../../projects/projects.module').then((m) => m.ProjectsModule),
    },
    {
        path: 'access',
        loadChildren: () => import('../../access/access.module').then((m) => m.AccessModule),
    },
    {
        path: 'dictionaries',
        loadChildren: () =>
            import('../../dictionaries/dictionaries.module').then((m) => m.DictionariesModule),
    },
    {
        path: 'pages/home',
        loadChildren: () => import('../../pages/home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'pages/static-pages',
        loadChildren: () =>
            import('../../pages/static-pages/static-pages.module').then((m) => m.StaticPagesModule),
    },
    {
        path: 'prizes',
        loadChildren: () => import('../../prizes/prizes.module').then((m) => m.PrizesModule),
    },
];
